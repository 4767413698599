<template>
  <div class="table-responsive">
    <b-table
      hover 
      class = "text-nowrap"
      v-if="items?.length > 0 && !isProcessing" 
      :items="items" 
      :fields="fields"
    >
    <template #head(select)>
        <b-form-checkbox 
          v-model="selectAll" 
          @change="toggleSelectAll"
          aria-label="Select All"
        ></b-form-checkbox>
      </template>
      <template #cell(select)="data">
        <b-form-checkbox 
          v-model="data.item.selected"
          @change="toggleRowSelection(data.item)"
        ></b-form-checkbox>
      </template>
      <template #cell(avatar_with_title)="data">
        <div class="d-flex align-items-center gap-2">
          <img 
              v-if="data.item.oflMember === 'is_member' || data.item.oflMember == 1" 
              src="@/assets/images/Union.png" 
              alt="Membership Status" 
              class="dashboard-avatar " 
            />
          <img
          :src="data.item.avatar ? data.item.avatar : require('@/assets/images/placeholder.png')" 
            alt="Avatar"
            :class="['dashboard-avatar', 'me-2', avatarStyle]"
            @error="handleImageError"
          />
       
          <span> {{ data.item.name }}</span>
          
        </div>
      </template>
      <template #cell(social_links)="data">
        <div>    
              <a 
                v-for="(item, index) in data.item.social_links" 
                :key="index" 
                :href="item?.social_media_url" 
                target="_blank"
              >
              <img 
               v-if="getSocialImage(item.social_media_type)" 
                :src="getSocialImage(item.social_media_type)" 
                class="Social-link me-2 cursor-pointer" 
                alt="Social Media Icon"
              />
             </a>
            </div>
    </template>
        <template #cell(action)="data">
    <div class="d-flex">  
      <i class="fas fa-edit text-muted p-0 action-icon-weight cursor-pointer" @click="$emit('editItem',data.item.id)"></i>
      <i class="text-muted p-0 ms-2 fas fa-trash-alt action-icon-weight cursor-pointer" @click="$emit('deleteItem',data.item.id)"></i>
    
  </div>
</template>
      <template #cell(custom_cell)>
  <slot name ='custom-option'>

  </slot>
</template>

     
    </b-table>

    <div v-else class="text-center">

      <span v-if="!isProcessing && items?.length < 1">No Record Found</span>
    <div v-else>
      <i class="fas fa-spinner fa-spin"></i> 
    </div>
    
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    fields: {
      type : Array,
      required: true,
    },
    isProcessing: {
     type: Boolean,
     default: false,

    },
    avatarStyle: {
    type: String,
    default:'',
    }
  },
  data() {
    return {
      selectAll: false,
      selectedRows: [], 
     
    };
  },
  computed: {
    itemsWithSelection() {
      return this.items.map(item => ({ ...item, selected: false }));
    },
  
  },
  methods: {
    getSocialImage(key) {
      if (!key) return ; 
      key = key.trim().toLowerCase(); 
      switch (key) {
        case 'facebook':
          return require('@/assets/images/fb.png'); 
        case 'instagram':
          return require('@/assets/images/insta.png');
        case 'twitter':
          return require('@/assets/images/x.png');
        // case 'google':
        //   return require('@/assets/images/yelp.png');
        default:
          return ;
      }
    },
    toggleSelectAll() {
      this.items.forEach(item => (item.selected = this.selectAll));
      this.updateSelectedRows();
    },
    toggleRowSelection(item) {
      if (item.selected) {
        this.selectedRows.push(item);
      } else {
        this.selectedRows = this.selectedRows.filter(selected => selected.email !== item.email);
      }
      this.$emit("update:selectedRows", this.selectedRows.map(item=>item.id));
    },
    updateSelectedRows() {
       if (this.selectAll) {
        this.items = this.items.map(item => ({ ...item, selected: true }));
        this.selectedRows= this.items;
      }
      else
      {
       this.items = this.items.map(item => ({ ...item, selected: false }));
       this.selectedRows= [];
      }
      this.$emit("update:selectedRows", this.selectedRows.map(item=>item.id));
    },
    handleImageError(event) {
      event.target.src = require('@/assets/images/placeholder.png');
    },
  }
};
</script>

<style>
.remove-chevron.dropdown-toggle::after{
  display: none !important;
}
</style>
